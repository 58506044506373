const Languages =[
    { id: 'af-ZA', name: 'Afrikaans' },
    { id: 'ar-AE', name: 'Arabic (United Arab Emirates)' },
    { id: 'ar-SA', name: 'Arabic (Saudi Arabia)' },
    { id: 'be-BY', name: 'Belarusian' },
    { id: 'bg-BG', name: 'Bulgarian' },
    { id: 'bn-BD', name: 'Bengali (Bangladesh)' },
    { id: 'ca-ES', name: 'Catalan (Spain)' },
    { id: 'ch-GU', name: 'Chamorro (Guam)' },
    { id: 'cn-CN', name: 'Chinese (China)' },
    { id: 'cs-CZ', name: 'Czech (Czech Republic)' },
    { id: 'cy-GB', name: 'Welsh (United Kingdom)' },
    { id: 'da-DK', name: 'Danish (Denmark)' },
    { id: 'de-AT', name: 'German (Austria)' },
    { id: 'de-CH', name: 'German (Switzerland)' },
    { id: 'de-DE', name: 'German (Germany)' },
    { id: 'el-GR', name: 'Greek (Greece)' },
    { id: 'en-AU', name: 'English (Australia)' },
    { id: 'en-CA', name: 'English (Canada)' },
    { id: 'en-GB', name: 'English (United Kingdom)' },
    { id: 'en-IE', name: 'English (Ireland)' },
    { id: 'en-NZ', name: 'English (New Zealand)' },
    { id: 'en-US', name: 'English (United States)' },
    { id: 'eo-EO', name: 'Esperanto' },
    { id: 'es-ES', name: 'Spanish (Spain)' },
    { id: 'es-MX', name: 'Spanish (Mexico)' },
    { id: 'et-EE', name: 'Estonian (Estonia)' },
    { id: 'eu-ES', name: 'Basque (Spain)' },
    { id: 'fa-IR', name: 'Persian (Iran)' },
    { id: 'fi-FI', name: 'Finnish (Finland)' },
    { id: 'fr-CA', name: 'French (Canada)' },
    { id: 'fr-FR', name: 'French (France)' },
    { id: 'ga-IE', name: 'Irish (Ireland)' },
    { id: 'gd-GB', name: 'Scottish Gaelic (United Kingdom)' },
    { id: 'gl-ES', name: 'Galician (Spain)' },
    { id: 'he-IL', name: 'Hebrew (Israel)' },
    { id: 'hi-IN', name: 'Hindi (India)' },
    { id: 'hr-HR', name: 'Croatian (Croatia)' },
    { id: 'hu-HU', name: 'Hungarian (Hungary)' },
    { id: 'id-ID', name: 'Indonesian (Indonesia)' },
    { id: 'it-IT', name: 'Italian (Italy)' },
    { id: 'ja-JP', name: 'Japanese (Japan)' },
    { id: 'ka-GE', name: 'Georgian (Georgia)' },
    { id: 'kk-KZ', name: 'Kazakh (Kazakhstan)' },
    { id: 'kn-IN', name: 'Kannada (India)' },
    { id: 'ko-KR', name: 'Korean (South Korea)' },
    { id: 'ky-KG', name: 'Kyrgyz (Kyrgyzstan)' },
    { id: 'lt-LT', name: 'Lithuanian (Lithuania)' },
    { id: 'lv-LV', name: 'Latvian (Latvia)' },
    { id: 'ml-IN', name: 'Malayalam (India)' },
    { id: 'mr-IN', name: 'Marathi (India)' },
    { id: 'ms-MY', name: 'Malay (Malaysia)' },
    { id: 'ms-SG', name: 'Malay (Singapore)' },
    { id: 'nb-NO', name: 'Norwegian Bokmål (Norway)' },
    { id: 'nl-BE', name: 'Dutch (Belgium)' },
    { id: 'nl-NL', name: 'Dutch (Netherlands)' },
    { id: 'no-NO', name: 'Norwegian (Norway)' },
    { id: 'pa-IN', name: 'Punjabi (India)' },
    { id: 'pl-PL', name: 'Polish (Poland)' },
    { id: 'pt-BR', name: 'Portuguese (Brazil)' },
    { id: 'pt-PT', name: 'Portuguese (Portugal)' },
    { id: 'ro-RO', name: 'Romanian (Romania)' },
    { id: 'ru-RU', name: 'Russian (Russia)' },
    { id: 'si-LK', name: 'Sinhala (Sri Lanka)' },
    { id: 'sk-SK', name: 'Slovak (Slovakia)' },
    { id: 'sl-SI', name: 'Slovenian (Slovenia)' },
    { id: 'sq-AL', name: 'Albanian (Albania)' },
    { id: 'sr-RS', name: 'Serbian (Serbia)' },
    { id: 'sv-SE', name: 'Swedish (Sweden)' },
    { id: 'ta-IN', name: 'Tamil (India)' },
    { id: 'te-IN', name: 'Telugu (India)' },
    { id: 'th-TH', name: 'Thai (Thailand)' },
    { id: 'tl-PH', name: 'Tagalog (Philippines)' },
    { id: 'tr-TR', name: 'Turkish (Turkey)' },
    { id: 'uk-UA', name: 'Ukrainian (Ukraine)' },
    { id: 'vi-VN', name: 'Vietnamese (Vietnam)' },
    { id: 'zh-CN', name: 'Chinese (China)' },
    { id: 'zh-HK', name: 'Chinese (Hong Kong)' },
    { id: 'zh-SG', name: 'Chinese (Singapore)' },
    { id: 'zh-TW', name: 'Chinese (Taiwan)' },
    { id: 'zu-ZA', name: 'Zulu (South Africa)' }
]

export {Languages}